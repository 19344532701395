import { Bill } from '@enview/interface/types/bills/Bill';
import { faBell, faEnvelope, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import difference from 'lodash-es/difference';
import React, { ReactElement, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { BillAPI, TeamAPI } from '../../../api';
import Can from '../../../components/Can';
import EditIcon from '../../../components/svg/EditIcon';
import { g as abilityGlossary } from '../../../config/ability';
import { checkPermission, getSelectedTeamIds, getTeamMode } from '../../../dux';
import { getBillAlias } from '../../../helpers/BillHelper';
import { mobileMediaQuery } from '../../../helpers/mediaQueries';
import '../Buttons/Button.scss';
import SplitButtonWithDropDown from '../Buttons/SplitButtonWithDropDown';
import { CustomToggleDropdownOption } from '../DropDowns/CustomToggleSelectDropDown';
import ConfirmActionPopover from '../Popovers/ConfirmActionPopover';
import TeamSelector from '../TeamSelector';
import GateTooltip, { GateTooltipFeature } from '../Tooltips/TooltipGate';
import EmailBillForm from './EmailBillForm';
import TrackConfirmButton from './TrackConfirmButton';

const PLACEHOLDER_TEXT = 'Bill Shortname';
type BillTrackingButtonProps = {
  bill: Bill;
  hasDropDown: boolean;
  hasStatusIndicator?: boolean;
};

const getTeamIdsTrackingBill = (bill: Bill): number[] => {
  return bill.teams ? bill.teams.map((team) => team.id) : [];
};

const BillTrackingButton = (props: BillTrackingButtonProps): ReactElement => {
  const dispatch = useDispatch();
  const { bill, hasDropDown, hasStatusIndicator } = props;
  const [trackPopoverVisible, setTrackPopoverVisible] = useState(false);
  const [emailBillPopoverVisible, setEmailBillPopoverVisible] = useState(false);
  const [showBillAliasField, setShowBillAliasField] = useState(false);
  const teamModeId = useSelector(getTeamMode);
  const selectedTeams = useSelector(getSelectedTeamIds);
  const alias = getBillAlias(bill.userAliases, teamModeId);
  const teamIdsTrackingBill = getTeamIdsTrackingBill(bill);
  const getBillTrackedStatus = (): boolean => {
    // bill.teams is array of user teams that is tracking this bill
    // different from bill.teamsTracking which is an array of all teams tracking the bill
    return !!bill.teams && bill.teams.length > 0;
  };

  const [trackBill] = BillAPI.endpoints.trackBill.useMutation();
  const [untrackBill] = BillAPI.endpoints.untrackBill.useMutation();
  const [setBillAlias] = BillAPI.endpoints.setBillAlias.useMutation();
  const [clearBillAlias] = BillAPI.endpoints.clearBillAlias.useMutation();
  const { data: teamMemberships } =
    TeamAPI.endpoints.getUsersTeamMemberships.useQuery();
  const teamMembershipsIds = teamMemberships?.map((team) => team.teamId) || [];
  const { data: trackingLimit } =
    BillAPI.endpoints.getTrackedBillsLimit.useQuery(teamMembershipsIds);
  const canTrackMoreBills = useMemo<boolean>(() => {
    if (trackingLimit?.limit && trackingLimit.bills.length >= trackingLimit.limit) {
      return false;
    }
    return true;
  }, [trackingLimit]);

  const getTrackButtonText = (): string => {
    return getBillTrackedStatus() ? 'Tracking' : 'Track';
  };
  const trackButtonText = getTrackButtonText();

  const handleTrack = (teamIds: number[]): void => {
    if (teamIds.length) {
      void trackBill({ bill, teamIds });
    }
  };

  const handleUntrack = (teamIds: number[]): void => {
    if (teamIds.length) {
      void untrackBill({ bill, teamIds });
    }
  };

  const handleBillTracking = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    event.stopPropagation();

    setTrackPopoverVisible(false);
    const newTeamsToTrack = difference(selectedTeams, teamIdsTrackingBill);
    const oldTeamsToUntrack = difference(teamIdsTrackingBill, selectedTeams);
    handleTrack(newTeamsToTrack);
    handleUntrack(oldTeamsToUntrack);
  };

  const toggleTrackingCallback = (evt: React.SyntheticEvent): void => {
    /*
      Currently, SplitButtonWithDropdown operates two separate popups: one
      managed by react-tiny-popover, and the generated by the `options` object
      defined below. This messy hack prevents the "track" popover with a team
      selector from opening when the user clicks on the right half of SplitButtonWithDropdown
      UPDATE: 2/2/2023 - This button really should be redesigned
      Have to manually close tracking dropdown when it currently is visible and reclick dropdown trigger
    */
    if (
      trackPopoverVisible &&
      evt.currentTarget?.lastElementChild?.lastElementChild?.id ==
        'bill-header-dropdown-trigger'
    )
      setTrackPopoverVisible(false);
    if (evt.currentTarget.tagName.toLowerCase() !== 'div' || !hasDropDown) {
      if (!trackPopoverVisible) {
        setTrackPopoverVisible(true);
      } else {
        setTrackPopoverVisible(false);
      }
    }
  };

  const toggleEmailingCallback = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    const isPermitted = dispatch(
      checkPermission(abilityGlossary.SEND, abilityGlossary.BILL),
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (isPermitted) {
      setEmailBillPopoverVisible(true);
    }
  };

  const toggleAddBillAlias = (billAliasContent: string): void => {
    if (alias && billAliasContent.trim() === '') {
      void clearBillAlias({ bill, alias });
      return;
    }
    const newAlias = {
      ...(alias ?? {}),
      teamId: teamModeId,
      billId: bill.id,
      alias: billAliasContent.trim(),
    };
    if (!alias || (alias && alias.alias !== newAlias.alias)) {
      void setBillAlias({ bill, alias: newAlias });
    }
  };

  const canAddAlias = () => {
    return dispatch(
      checkPermission(abilityGlossary.CREATE, abilityGlossary.BILL_SHORTNAME, true),
    );
  };

  const options = () => {
    if (canAddAlias()) setShowBillAliasField(true);

    const optionsList: CustomToggleDropdownOption[] = [
      {
        label: getBillTrackedStatus() ? 'Untrack' : 'Track',
        value: 0,
        icon: <FontAwesomeIcon color="#f68c24" icon={faBell} />,
        onSelect: toggleTrackingCallback,
      },
      {
        label: 'Send via Email',
        value: 1,
        icon: <FontAwesomeIcon color="#979797" icon={faEnvelope} />,
        onSelect: toggleEmailingCallback,
      },
      {
        label: 'Print to PDF',
        value: 2,
        icon: <FontAwesomeIcon color="#979797" icon={faPrint} />,
        onSelect: () => window.print(),
      },
    ];

    if (showBillAliasField) {
      optionsList.push({
        label: PLACEHOLDER_TEXT,
        value: 2,
        isTextBox: true,
        icon: <EditIcon title="Save Shortname" />,
        onSelect: () => {},
        textBoxValue: alias ? alias.alias : undefined,
        onSaveText: toggleAddBillAlias,
      });
    }

    return optionsList;
  };

  const getTargetTrackedButton = (
    can: boolean,
    style: React.CSSProperties,
  ): ReactElement => {
    const isTracked = getBillTrackedStatus();
    if (!hasStatusIndicator) {
      /* We still need button click events to fire for the feature-gating tooltip,
      so we can't use the `disabled` property and instead have to change CSS and click events ourselves
      */
      return (
        <button
          aria-disabled={!can}
          className={`btn btn-sm btn-secondary ${can ? '' : 'disabled'}`}
          style={isTracked ? style : {}}
          type="button"
        >
          {trackButtonText}
        </button>
      );
    } else {
      return (
        <button
          aria-disabled={!can}
          className={`btn btn-sm btn-secondary toggle tracking-toggle ${
            can ? '' : ' disabled'
          }`}
          type="button"
        >
          <div className={`toggle-indicator ${isTracked ? 'tracking' : 'track'}`} />
          {trackButtonText}
        </button>
      );
    }
  };

  const getTargetElement = (can: boolean): ReactElement => {
    const style = { backgroundColor: '#f68c24', color: '#fff' };
    return hasDropDown ? (
      <>
        <SplitButtonWithDropDown
          buttonLabel={trackButtonText}
          dropDownTriggerId="bill-header-dropdown-trigger"
          isDisabled={!can}
          isToggled={getBillTrackedStatus()}
          onButtonClicked={toggleTrackingCallback}
          options={options()}
          toggledOnStyle={style}
        />
      </>
    ) : (
      getTargetTrackedButton(can, style)
    );
  };

  const trackConfirmBody = t('bill.modals.track.trackText');
  const trackBillPopoverContent = (
    <>
      <p className="popover-confirm-body font-weight-bold">{trackConfirmBody}</p>
      <TeamSelector
        initialSelection={teamIdsTrackingBill.length ? teamIdsTrackingBill : undefined}
      />
      <div className="text-center mt-3">
        <TrackConfirmButton
          isBillTracked={getBillTrackedStatus()}
          trackDisabled={canTrackMoreBills ? false : true}
          updateBillTracking={handleBillTracking}
        />
      </div>
      {trackingLimit && trackingLimit.limit && (
        <>
          <div className={`upgrade-promo mt-3 ${canTrackMoreBills ? 'mb-4' : 'mb-5'}`}>
            <Trans
              components={{
                upgradeLink: <a href={t('urls.upgradeAccount')} target="_blank" />,
              }}
              i18nKey="bill.modals.track.promo"
              key="bill.modals.track.promo"
            />
          </div>
          <div className="track-count-ribbon">
            {canTrackMoreBills ? (
              <p>
                {`${trackingLimit.bills.length}/${trackingLimit.limit} bills tracked`}
              </p>
            ) : (
              <>
                <p>Maximum bills tracked.</p>
                <p>Untrack bills to track more.</p>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
  return (
    <>
      <Can I={abilityGlossary.TRACK} a={abilityGlossary.BILL} passThrough>
        {(can: boolean) => (
          <>
            <span id={`track-bill-${bill.id}`}>
              <ConfirmActionPopover
                arrowColor="#f0f3f8"
                closePopup={() => setTrackPopoverVisible(false)}
                content={trackBillPopoverContent}
                disableReposition={mobileMediaQuery.matches}
                key={trackButtonText + bill.id}
                show={trackPopoverVisible}
                showArrowContainer
                target={getTargetElement(can)}
                // TODO: investigate why popover fails in small screens
                toggleCallback={can ? toggleTrackingCallback : () => {}}
              />
            </span>
            {!can && (
              <GateTooltip
                accountType="free"
                anchorId={`track-bill-${bill.id}`}
                customContent={
                  <Trans
                    components={{
                      trackLink: <a href={t('urls.createAccount')} target="_blank" />,
                    }}
                    i18nKey="featureGating.tooltipText.trackButton"
                    key="featureGating.tooltipText.trackButton"
                  />
                }
                featureName={GateTooltipFeature.BillTracking}
                place="left"
              />
            )}
          </>
        )}
      </Can>
      <ConfirmActionPopover
        closePopup={() => setEmailBillPopoverVisible(false)}
        content={
          <EmailBillForm
            bill={bill}
            onDone={() => setTimeout(() => setEmailBillPopoverVisible(false), 2000)}
          />
        }
        show={emailBillPopoverVisible}
        target={<div />}
        toggleCallback={() => setEmailBillPopoverVisible(!emailBillPopoverVisible)}
      />
    </>
  );
};

BillTrackingButton.defaultProps = {
  hasDropDown: false,
  hasStatusIndicator: false,
};

export default BillTrackingButton;
