/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import CloseIcon from './svg/CloseIcon';

class OptionalLoginModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: this.props.openOptionalLoginModal,
    };
  }

  close = () => {
    this.props.closeModal();
  };

  render() {
    return (
      <Modal
        isOpen={this.state.modalIsOpen}
        onAfterOpen={() => {}}
        onRequestClose={() => {}}
        closeTimeoutMS={100}
        className="react-modal optional-login-modal centralized-modal"
        overlayClassName="react-modal-overlay"
        contentLabel="Modal"
      >
        <div className="close-container mb-3">
          <div className="row m-0">
            <div className="col p-0">
              <button className="float-right unstyled" onClick={this.close}>
                <CloseIcon style={{ width: '14px' }} />
              </button>
            </div>
          </div>
        </div>
        <div className="mx-3 my-4">
          <h3>You Are Logged Out of Plural</h3>
          <p className="my-4">
            You can still view this page without being logged into Plural. If you would
            like to view this page within your account,{' '}
            <Link to="/login">click here to log in.</Link>
          </p>
        </div>
      </Modal>
    );
  }
}

OptionalLoginModal.propTypes = {
  openOptionalLoginModal: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
};

export default OptionalLoginModal;
