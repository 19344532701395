import React from 'react';
import Can from '../../../components/Can';
import { g as abilityGlossary } from '../../../config/ability';
import BillPositionButton from './BillPositionButton';
import { Bill } from '@enview/interface/types/bills/Bill';

interface BillPositionProps {
  bill: Bill;
  showPlaceholderText?: boolean;
}

export const BillPosition: React.FC<BillPositionProps> = ({
  bill,
  showPlaceholderText,
}) => {
  return (
    <Can I={abilityGlossary.CREATE} a={abilityGlossary.BILL_POSITION} passThrough>
      {(can: boolean) => (
        <div
          className="d-flex flex-wrap"
          onClick={(e: React.SyntheticEvent) => e.stopPropagation()}
          style={{ lineHeight: '20px', marginTop: '6px', width: 'fit-content' }}
        >
          <BillPositionButton
            bill={bill}
            isDisabled={!can}
            showPlaceholderText={showPlaceholderText}
          />
        </div>
      )}
    </Can>
  );
};

export default BillPosition;
