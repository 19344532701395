import React from 'react';

export default () => (
    <svg
        width="0.75em"
        height="1em"
        viewBox="0 0 384 448"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ verticalAlign: "-0.125em" }}
    >
        <path
            d="M128 16C128 7.2 135.2 0 144 0H368C376.8 0 384 7.2 384 16C384 24.8 376.8 32 368 32H283L135.3 416H240C248.8 416 256 423.2 256 432C256 440.8 248.8 448 240 448H16C7.2 448 0 440.8 0 432C0 423.2 7.2 416 16 416H101L248.7 32H144C135.2 32 128 24.8 128 16Z"
            fill="black"
        />
    </svg>
);
