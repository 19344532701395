import React from 'react';

export default () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 480 384"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ verticalAlign: "-0.125em" }}
  >
    <path
      d="M32 0C23.5131 0 15.3737 3.37142 9.37258 9.37258C3.37142 15.3737 0 23.5131 0 32C0 40.4869 3.37142 48.6263 9.37258 54.6274C15.3737 60.6286 23.5131 64 32 64C40.4869 64 48.6263 60.6286 54.6274 54.6274C60.6286 48.6263 64 40.4869 64 32C64 23.5131 60.6286 15.3737 54.6274 9.37258C48.6263 3.37142 40.4869 0 32 0ZM144 16H128V48H144H464H480V16H464H144ZM144 176H128V208H144H464H480V176H464H144ZM144 336H128V368H144H464H480V336H464H144ZM64 192C64 183.513 60.6286 175.374 54.6274 169.373C48.6263 163.371 40.4869 160 32 160C23.5131 160 15.3737 163.371 9.37258 169.373C3.37142 175.374 0 183.513 0 192C0 200.487 3.37142 208.626 9.37258 214.627C15.3737 220.629 23.5131 224 32 224C40.4869 224 48.6263 220.629 54.6274 214.627C60.6286 208.626 64 200.487 64 192ZM32 320C23.5131 320 15.3737 323.371 9.37258 329.373C3.37142 335.374 0 343.513 0 352C0 360.487 3.37142 368.626 9.37258 374.627C15.3737 380.629 23.5131 384 32 384C40.4869 384 48.6263 380.629 54.6274 374.627C60.6286 368.626 64 360.487 64 352C64 343.513 60.6286 335.374 54.6274 329.373C48.6263 323.371 40.4869 320 32 320Z"
      fill="black"
    />
  </svg>
);
