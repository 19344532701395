import { Jurisdiction } from '@enview/interface/types/Jurisdiction';
import { orderFilterOptions } from '../../helpers/BillListFilterHelper';
import { OptionType } from '../Shared/DropDowns/MultipleSelectDropDown';

export enum OrgActions {
  ACTIVATE = 'activate',
  SUSPEND = 'suspend',
  CONVERT_PRO = 'convert_pro',
  CONVERT_TRIAL = 'convert_trial',
  CONVERT_FREE = 'convert_free',
  CONVERT_STARTER = 'convert-starter',
  DELETE = 'delete',
}

export const ACCOUNT_SUSPENDED_FILTER_OPTIONS: OptionType<boolean | undefined>[] = [
  { label: 'All', value: undefined },
  { label: 'Active', value: false },
  { label: 'Suspended', value: true },
];

export const STATUS_FILTER_OPTIONS: OptionType<string>[] = [
  { label: 'All', value: '' },
  { label: 'Active', value: 'active' },
  { label: 'Suspended', value: 'suspended' },
];

export const NOTIFICATION_FILTER_OPTIONS: OptionType<string>[] = [
  { label: 'All', value: '' },
  { label: 'Realtime', value: 'real_time_bill' },
  { label: 'Daily', value: 'daily_bill' },
  { label: 'Weekly', value: 'weekly_bill' },
  { label: 'Recommended Bills', value: 'recommended_weekly' },
  { label: 'None', value: 'none' },
];

export const USE_CASE_FILTER: OptionType<string>[] = [
  { label: 'All', value: '' },
  { label: 'Finding new legislation', value: 'Finding new legislation' },
  {
    label: 'Collaborating with other policy professionals',
    value: 'Collaborating with other policy professionals',
  },
  {
    label: 'Sharing information with my constituents and members',
    value: 'Sharing information with my constituents and members',
  },
];

export const LEG_WORK_EXPERIENCE_FILTER: OptionType<string>[] = [
  { label: 'All', value: '' },
  { label: 'I have little to no experience tracking bills', value: 'none' },
  { label: 'I have some experience tracking bills', value: 'average' },
  { label: "I'm very experienced with tracking bills", value: 'experienced' },
];

export const HELP_LEVEL_FILTER: OptionType<string>[] = [
  { label: 'All', value: '' },
  { label: "I'd like to start on my own", value: 'none' },
  { label: "I'm just curious about Plural and looking around", value: 'average' },
  {
    label: "I'd like to have a Plural specialist walk me through",
    value: 'extensive',
  },
];

export const ACCOUNT_TYPE_FILTER_OPTIONS = [
  { label: 'All', value: '' },
  { label: 'Pro', value: 'pro' },
  { label: 'Basic', value: 'basic' },
  { label: 'Trial', value: 'trial' },
  { label: 'Free', value: 'free' },
];

export const USER_ACTION_OPTIONS = [
  { label: 'Activate', value: OrgActions.ACTIVATE },
  { label: 'Suspend', value: OrgActions.SUSPEND },
];

export const ACTION_OPTIONS = [
  { label: 'Activate', value: OrgActions.ACTIVATE },
  { label: 'Suspend', value: OrgActions.SUSPEND },
  { label: 'Convert to Pro', value: OrgActions.CONVERT_PRO },
  { label: 'Convert to Basic', value: OrgActions.CONVERT_STARTER },
  { label: 'Convert to Trial', value: OrgActions.CONVERT_TRIAL },
  { label: 'Convert to Free', value: OrgActions.CONVERT_FREE },
  { label: 'Delete', value: OrgActions.DELETE },
];

export const jurisdictionOptions = (
  supportedJurisdictions: Jurisdiction[],
): OptionType<string>[] => {
  return [
    { label: 'All', value: '' },
    ...orderFilterOptions(
      supportedJurisdictions.map((j) => {
        return { label: j.name, value: j.abbreviation };
      }),
    ),
  ];
};

export const getJurisdictionsList = (
  jurisdictions: Jurisdiction[],
  supportedJurisdictions: Jurisdiction[],
): string => {
  if (supportedJurisdictions && supportedJurisdictions.length <= jurisdictions.length) {
    return `(${jurisdictions.length}) Full Access`;
  }
  return `(${jurisdictions.length}) ${jurisdictions
    .map((jur: Jurisdiction) => jur.abbreviation.toUpperCase())
    .sort()
    .join(', ')}`;
};

export const customSort = (
  rows: unknown[],
  field: string | ((row: any, rowIndex?: number) => React.ReactNode),
  direction: 'desc' | 'asc',
): unknown[] => {
  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: 'base',
  });

  return [...rows].sort((a: any, b: any) => {
    let aValue, bValue;

    if (typeof field === 'function') {
      aValue = field(a);
      bValue = field(b);
    } else {
      aValue = a[field];
      bValue = b[field];
    }

    if (direction === 'desc') {
      return collator.compare(`${bValue}`, `${aValue}`);
    }

    return collator.compare(`${aValue}`, `${bValue}`);
  });
};
