/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ReactElement, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { OrganizationAPI } from '../../api';
import { authenticate } from '../../dux';
import { State } from '../../dux/@types';
import { authenticateForMasqueradedUser } from '../../dux/AuthenticationDux';
import { Trans, useTranslation } from 'react-i18next';
import { useUrlCreator } from '../../helpers/UrlHelper';

type LoginForm = {
  email: string;
  password: string;
  rememberMe?: boolean;
};

const Login = (): ReactElement => {
  const { t } = useTranslation();
  const suspendedUserMessage = (
    <p className="alert alert-danger flash-error">
      <Trans
        components={{
          startedLink: <a href={t('urls.getStartedLink')} />,
          mailLink: <a href={t('urls.mailtoSupport')} />,
        }}
        i18nKey={'error.suspendedUser'}
      />
    </p>
  );

  // Needs upgrade to at least react-hook-form@^7.0.0
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { register, handleSubmit, errors, getValues } = useForm();
  const loginError = useSelector((state: State) => state.authentication.loginError);
  const authError = useSelector(
    (state: State) => state.authentication.authErrorMessage,
  );
  const needsMfaCheck = useSelector(
    (state: State) => state.authentication.needsMfaCheck,
  );
  const needsMfaRegistration = useSelector(
    (state: State) => state.authentication.needsMfaRegistration,
  );
  const showSuspendedUserMessage = useSelector(
    (state: State) => state.authentication.showSuspendedUserMessage,
  );

  const { data: ipAuthorization } =
    OrganizationAPI.endpoints.getIPAuthorization.useQuery(undefined);

  const sessionMatch =
    /\/login\/(\b[a-f0-9]{8}\b-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-\b[a-f0-9]{12}\b)/.exec(
      window.location.pathname,
    );
  const masqueradeSessionId = sessionMatch ? sessionMatch[1] : undefined;
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    if (needsMfaCheck) {
      history.push('/mfa-verification', { rememberMe: getValues('rememberMe') });
    } else if (needsMfaRegistration) {
      history.push('/mfa-registration', { rememberMe: getValues('rememberMe') });
    }
  }, [history, needsMfaCheck, needsMfaRegistration]);

  useEffect(() => {
    if (masqueradeSessionId)
      dispatch(authenticateForMasqueradedUser(masqueradeSessionId));
  }, [dispatch, masqueradeSessionId]);

  const onSubmit = (data: LoginForm): void => {
    dispatch(authenticate(data.email, data.password, data.rememberMe));
  };

  const urlCreator = useUrlCreator();
  const ipAuthUrl = urlCreator(`/get-started`);
  const buttonUrl = ipAuthorization ? ipAuthUrl : t('urls.getStartedLink');
  return (
    <>
      <div className="mb-5 text-center registration-header">{t('login.header')}</div>

      <div className="error-message">
        {loginError && <p className="alert alert-danger flash-error">{loginError}</p>}
        {authError && <p className="alert alert-danger flash-error">{authError}</p>}
        {showSuspendedUserMessage && suspendedUserMessage}
      </div>

      <form className="form registration-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <input
            className="form-control form-field"
            id="root_email"
            name="email"
            placeholder="Email address"
            ref={register({ required: true })}
            type="email"
          />
        </div>
        {errors.email && (
          <div className="error alert alert-danger">{t('error.requiredFormField')}</div>
        )}

        <div className="row">
          <input
            className="form-control form-field"
            id="root_password"
            name="password"
            placeholder="Password"
            ref={register({ required: true })}
            type="password"
          />
        </div>
        {errors.password && (
          <div className="error alert alert-danger">{t('error.requiredFormField')}</div>
        )}

        <div className="row justify-content-between">
          <div className="row checkbox">
            <label className="form-field">
              <input id="rememberMe" name="rememberMe" ref={register} type="checkbox" />
              <span>{t('login.rememberMe')}</span>
            </label>
          </div>
          <div className="login-help">
            <p>
              <Link id="forgot-password-link" to="/forgot-password">
                <small>{t('login.forgotPassword')}</small>
              </Link>
            </p>
          </div>
        </div>

        <div className="submit">
          <button className="btn btn-primary w-100" id="login-button" type="submit">
            {t('login.logInButton')}
          </button>
        </div>
      </form>

      <div className="footnote">
        <Trans
          components={{ startedLink: <a className="" href={buttonUrl} /> }}
          i18nKey={'login.dontHaveAnAccount'}
        />
      </div>
    </>
  );
};
export default Login;
