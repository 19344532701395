import {
  OrganizationUser,
  OrganizationUserRole,
} from '@enview/interface/types/OrganizationUser';
import React, {
  PropsWithChildren,
  ReactElement,
  lazy,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TeamAPI } from '../../api';
import { getPendingTeamInvitesForUser, getTeamMode } from '../../dux';
import { State } from '../../dux/@types';
import { setOrgId } from '../../dux/TeamModeDux';
import { TeamInvitation } from '../../models/Team';
import MasqueradeUserSessionOn from '../Admin/MasqueradeUserSessionOn';
import NeedToUpgrade from '../Permissions/NeedToUpgrade';
import '../Regulations/Regulations.scss';
import EditSavedSearchModal from '../Shared/Modals/EditSavedSearchModal';
import ReportProblemModal from '../Shared/Modals/ReportProblemModal';
import { SavedSearchAlertModal } from '../Shared/Modals/SavedSearchAlertsModal';
import SavedSearchModal from '../Shared/Modals/SavedSearchModal';
import NewPrimarySidebar from './NavRedesign/NewPrimarySidebar';
import NewTopNav from './NavRedesign/TopNav/NewTopNav';
import './PrimaryView.scss';

const TeamInvitationRequests = lazy(() => import('../TeamView/TeamInvitationRequests'));
const ExportBillsModal = lazy(
  () => import('../Shared/Modals/ExportBills/ExportBillsModal'),
);
const UserActionModal = lazy(
  () => import('../Shared/Modals/UserAction/UserActionModal'),
);
const TagModal = lazy(() => import('../Shared/Modals/Tag/TagModal'));

type PrimaryLayoutProps = {
  // TODO: Make this optional to display logged out state
  organizationUser: OrganizationUser;
};

const PrimaryLayout = (props: PropsWithChildren<PrimaryLayoutProps>): ReactElement => {
  const { organizationUser, children } = props;
  let orgId: number | undefined;
  const teamModeId = useSelector(getTeamMode);
  const [sidebarMinimized, setSidebarMinimized] = useState(true);
  // const [showNotificationIndicator, setShowNotificationIndicator] = useState(false);

  const modalOpenTag = useSelector((state: State) => state.modals.modalOpenTag);
  const modalOpenUserAction = useSelector(
    (state: State) => state.modals.modalOpenUserAction,
  );
  const modalOpenExportBills = useSelector(
    (state: State) => state.modals.modalOpenExportBills,
  );
  const openRestrictionModal = useSelector(
    (state: State) => state.modals.modalOpenLoginRestriction,
  );
  const isSavedSearchModalOpen = useSelector(
    (state: State) => state.modals.modalOpenSavedSearch,
  );
  const isEditSavedSearchModalOpen = useSelector(
    (state: State) => state.modals.modalOpenEditSearch,
  );
  const isMasqueradeSession = useSelector(
    (state: State) => state.authentication.isMasqueradeSession,
  );
  const pendingTeamInvitations = useSelector(
    (state: State) => state.teams.pendingTeamInvitations,
  );
  const isSavedSearchAlertModalOpen = useSelector(
    (state: State) => state.modals.modalOpenSavedSearchAlert,
  );
  const isReportProblemModalOpen = useSelector(
    (state: State) => state.modals.modalOpenReportProblem,
  );
  const dispatch = useDispatch();

  const { data: teamMemberships } =
    TeamAPI.endpoints.getUsersTeamMemberships.useQuery(undefined);
  if (teamMemberships) {
    const currentTeam = teamMemberships.find((m) => m.teamId === teamModeId)?.team;
    orgId = currentTeam?.organizationId;
  }

  useEffect(() => {
    dispatch(getPendingTeamInvitesForUser());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setOrgId(orgId));
  }, [orgId]);

  const renderBannerContent = (
    pendingTeamInvitationsArg: TeamInvitation[],
    isMasqueradeSessionArg: boolean,
  ): ReactElement | undefined => {
    if (pendingTeamInvitationsArg.length) {
      return <TeamInvitationRequests />;
    }
    if (isMasqueradeSessionArg) {
      return <MasqueradeUserSessionOn />;
    }

    return undefined;
  };

  const isEnviewAdmin = useMemo(
    () =>
      organizationUser.roles.find(
        (role: OrganizationUserRole) => role.name === 'enview-admin',
      ),
    [organizationUser.roles],
  );

  const isOrgAdmin = useMemo(
    () =>
      !!organizationUser.roles.find(
        (role) => role.name === 'org-admin' && role.organizationId === orgId,
      ),
    [orgId, organizationUser],
  );

  const primaryWrapperClass = useMemo(
    () =>
      `primary-layout-wrapper${
        sidebarMinimized ? ' sidebar-minimized' : ''
      }${' d-flex flex-row'}`,
    [sidebarMinimized],
  );

  const bannerContent = renderBannerContent(
    pendingTeamInvitations || [],
    isMasqueradeSession || false,
  );

  const layoutClasses = ['primary-layout'];
  if (bannerContent) {
    layoutClasses.push('with-banner');
  }
  return (
    <div className={layoutClasses.join(' ')}>
      <div className="site-banner">{bannerContent}</div>

      <NewTopNav
        isEnviewAdmin={!!isEnviewAdmin}
        isOrgAdmin={isOrgAdmin}
        organizationUser={organizationUser}
        setSidebarMinimized={setSidebarMinimized}
        sidebarMinimized={sidebarMinimized}
      />

      <div className={primaryWrapperClass}>
        <NewPrimarySidebar
          isMinimized={sidebarMinimized}
          minimize={() => setSidebarMinimized(true)}
        />
        {modalOpenExportBills && <ExportBillsModal />}
        {modalOpenUserAction && <UserActionModal />}
        {modalOpenTag && <TagModal />}
        {openRestrictionModal && organizationUser && <NeedToUpgrade />}
        {isSavedSearchModalOpen && <SavedSearchModal />}
        {isEditSavedSearchModalOpen && <EditSavedSearchModal />}
        {isSavedSearchAlertModalOpen && <SavedSearchAlertModal />}
        {isReportProblemModalOpen && <ReportProblemModal />}
        <div
          className={`page-layout__viewport primary-layout-content${
            sidebarMinimized ? ' sidebar-minimized' : ''
          } d-flex flex-column`}
        >
          {children}
          <footer className="text-center m-3">
            <small>© Plural</small>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default PrimaryLayout;
