import {
  AdminSetFields,
  OrganizationUser,
} from '@enview/interface/types/OrganizationUser';
import queryString from 'query-string';
import * as Analytics from '../analytics/OrganizationSettingsAnalytics';
import base, { ApiTag } from './base';

const organizationUserAPI = base.injectEndpoints({
  endpoints: (build) => ({
    // The backend endpoint has additional filter parameters not yet in use here
    getOrganizationUsers: build.query<
      { data: OrganizationUser[]; count: number; offset: number; limit: number },
      {
        orgId?: string;
        state?: string;
        status?: string;
        notification?: string;
        limit?: number;
        offset?: number;
      }
    >({
      query: (params) => ({
        url: `/organization-users?${queryString.stringify(params, {
          skipEmptyString: true,
        })}`,
        method: 'GET',
      }),
      providesTags: (result, error, orgId) => [
        { type: ApiTag.ORGANIZATION_TAG, orgId },
      ],
    }),
    promoteMemberToOrgAdmin: build.mutation<void, { orgId: number; orgUserId: number }>(
      {
        query: ({ orgId, orgUserId }) => ({
          url: `/organization-user/${orgId}/${orgUserId}`,
          method: 'POST',
        }),
        onQueryStarted: ({ orgId, orgUserId }) => {
          Analytics.trackOrgUserRoleUpdated(orgUserId, orgId, 'admin');
        },
        invalidatesTags: (result, error, { orgId, orgUserId }) => [
          { type: ApiTag.ORGANIZATION_TAG, orgId },
          { type: ApiTag.ORGANIZATION_USER_TAG, id: orgUserId },
        ],
      },
    ),
    revokeOrgAdmin: build.mutation<void, { orgId: number; orgUserId: number }>({
      query: ({ orgId, orgUserId }) => ({
        url: `/organization-user/${orgId}/${orgUserId}`,
        method: 'DELETE',
      }),
      onQueryStarted: ({ orgId, orgUserId }) => {
        Analytics.trackOrgUserRoleUpdated(orgUserId, orgId, 'member');
      },
      invalidatesTags: (result, error, { orgId, orgUserId }) => [
        { type: ApiTag.ORGANIZATION_TAG, orgId },
        { type: ApiTag.ORGANIZATION_USER_TAG, id: orgUserId },
      ],
    }),
    suspendUser: build.mutation<void, { orgId: number; orgUserId: number }>({
      query: ({ orgId, orgUserId }) => ({
        url: `/organization-user/${orgUserId}/suspend/${orgId}`,
        method: 'POST',
      }),
      onQueryStarted: ({ orgId, orgUserId }) => {
        Analytics.trackOrgUserSuspended(orgUserId, orgId, true);
      },
      invalidatesTags: (result, error, { orgId, orgUserId }) => [
        { type: ApiTag.ORGANIZATION_TAG, orgId },
        { type: ApiTag.ORGANIZATION_USER_TAG, id: orgUserId },
      ],
    }),
    activateUser: build.mutation<void, { orgId: number; orgUserId: number }>({
      query: ({ orgId, orgUserId }) => ({
        url: `/organization-user/${orgUserId}/suspend/${orgId}`,
        method: 'DELETE',
      }),
      onQueryStarted: ({ orgId, orgUserId }) => {
        Analytics.trackOrgUserSuspended(orgUserId, orgId, false);
      },
      invalidatesTags: (result, error, { orgId, orgUserId }) => [
        { type: ApiTag.ORGANIZATION_TAG, orgId },
        { type: ApiTag.ORGANIZATION_USER_TAG, id: orgUserId },
      ],
    }),
    updateOrganizationUserAdminFields: build.mutation<
      void,
      { organizationUserId: number; fieldsToUpdate: AdminSetFields }
    >({
      query: ({ organizationUserId, fieldsToUpdate }) => ({
        url: `/v2/organization-users/${organizationUserId}/admin-fields`,
        method: 'PUT',
        data: fieldsToUpdate,
      }),
      invalidatesTags: (result, error, { organizationUserId }) => [
        { type: ApiTag.ORGANIZATION_TAG },
        { type: ApiTag.ORGANIZATION_USER_TAG, id: organizationUserId },
      ],
    }),
    removeUserFromOrg: build.mutation<void, { orgId: number; orgUserId: number }>({
      query: ({ orgId, orgUserId }) => ({
        url: `/organization-users/${orgUserId}/remove/${orgId}`,
        method: 'DELETE',
      }),
      onQueryStarted: ({ orgId, orgUserId }) => {
        Analytics.trackOrgUserRemoved(orgUserId, orgId);
      },
      invalidatesTags: (result, error, { orgId, orgUserId }) => [
        { type: ApiTag.ORGANIZATION_TAG, orgId },
        { type: ApiTag.ORGANIZATION_USER_TAG, id: orgUserId },
      ],
    }),
  }),
  overrideExisting: false,
});

export default organizationUserAPI;
