import { AccountType, Organization } from '@enview/interface/types/Organization';
import { OrganizationUser } from '@enview/interface/types/OrganizationUser';
import { AxiosResponse } from 'axios';
import assign from 'lodash-es/assign';
import get from 'lodash-es/get';
import { FullStoryAPI } from 'react-fullstory';
import { ThunkDispatch } from 'redux-thunk';
import {
  requestError,
  requestFailure,
  requestPending,
  requestSucceeded,
  requestSuccess,
  resetRequest,
  setOrganizationUser,
  setTeamMode,
} from '..';
import * as Analytics from '../../analytics/AuthAnalytics';
import baseAPI from '../../api/base';
import instance from '../../config/axiosConfig';
import { persistStateInLocalStorage } from '../../services/LocalStorageService';
import { Action, State, Thunk } from '../@types';
import { SignupType } from '../RegistrationDux';
import { requestSent } from '../RequestDux';
import { getMfaErrorMessage } from './mfaHelper';
import {
  AuthenticationState,
  LOAD_MFA_CHECK,
  LOAD_MFA_REGISTRATION_REQUIRED,
  LOGIN,
  LOGIN_FAILED,
  LOGOUT,
  MFA_REGISTRATION_FAILURE,
  MFA_REGISTRATION_INITIALIZED,
  MFA_REGISTRATION_SUCCESS,
  MFA_VERIFICATION_FAILURE,
  MFA_VERIFICATION_SUCCESS,
  SET_AUTH_ERROR,
  SUSPENDED_USER_ERROR,
} from './types';

// REQUEST NAMES
const REQUEST_LOGIN = 'RequestLogin';
const REQUEST_PASSWORD_RESET = 'RequestPasswordReset';
const REQUEST_SET_NEW_PASSWORD = 'RequestSetNewPassword';

// REDUCER
export default function reducer(
  state: AuthenticationState,
  action: Action,
): AuthenticationState {
  switch (action.type) {
    case SET_AUTH_ERROR:
      return assign({}, state, {
        authErrorMessage: action.authErrorMessage,
      });
    case LOAD_MFA_CHECK:
      return assign({}, state, {
        needsMfaCheck: true,
      });
    case LOAD_MFA_REGISTRATION_REQUIRED:
      return assign({}, state, {
        needsMfaRegistration: true,
      });
    case MFA_REGISTRATION_INITIALIZED:
      return assign({}, state, {
        mfaQrCodeData: action.qrCodeData,
      });
    case MFA_REGISTRATION_SUCCESS:
      return assign({}, state, {
        mfaRegistrationSuccessful: true,
        needsMfaRegistration: undefined,
      });
    case MFA_REGISTRATION_FAILURE:
      return assign({}, state, {
        mfaRegistrationErrorMessage: action.errorMessage,
      });
    case MFA_VERIFICATION_FAILURE:
      return assign({}, state, {
        mfaVerificationErrorMessage: action.errorMessage,
      });
    case MFA_VERIFICATION_SUCCESS:
      return assign({}, state, {
        mfaVerificationSuccessful: true,
        needsMfaCheck: undefined,
      });
    case LOGIN:
      return assign({}, state, {
        isLoading: false,
        authToken: action.authToken,
        sessionId: action.sessionId,
        isMasqueradeSession: action.isMasqueradeSession,
        rememberMe: action.rememberMe,
        loginError: null,
      });
    case SUSPENDED_USER_ERROR:
      return assign({}, state, {
        loginError: null,
        showSuspendedUserMessage: true,
      });
    case LOGIN_FAILED:
      return assign({}, state, {
        loginError: action.error,
        showSuspendedUserMessage: false,
      });
    default:
      return state || {};
  }
}

// SELECTORS
export const loginSucceeded = (state: State): boolean => {
  return requestSucceeded(state, REQUEST_LOGIN);
};

export const isPasswordResetRequestPending = (state: State): boolean => {
  return requestPending(state, REQUEST_PASSWORD_RESET);
};

export const passwordResetRequestSucceeded = (state: State): boolean => {
  return requestSucceeded(state, REQUEST_PASSWORD_RESET);
};

export const passwordResetRequestError = (state: State): Error | undefined => {
  return requestError(state, REQUEST_PASSWORD_RESET);
};

export const isSetNewPasswordPending = (state: State): boolean => {
  return requestPending(state, REQUEST_SET_NEW_PASSWORD);
};

export const setNewPasswordSucceeded = (state: State): boolean => {
  return requestSucceeded(state, REQUEST_SET_NEW_PASSWORD);
};

export const setNewPasswordError = (state: State): Error | undefined => {
  return requestError(state, REQUEST_SET_NEW_PASSWORD);
};

// ACTION CREATORS

export const setAuthErrorMessage = (authErrorMessage: string): Action => {
  return {
    type: SET_AUTH_ERROR,
    authErrorMessage,
  };
};

const loadMfaCheck = (): Action => {
  return {
    type: LOAD_MFA_CHECK,
  };
};

const loadMfaRegistrationRequired = (): Action => {
  return {
    type: LOAD_MFA_REGISTRATION_REQUIRED,
  };
};

const showSuspendedUserMessage = (): Action => {
  return {
    type: SUSPENDED_USER_ERROR,
  };
};

const mfaVerificationSuccess = (): Action => {
  return {
    type: MFA_VERIFICATION_SUCCESS,
  };
};

const mfaVerificationFailure = (errorMessage: string): Action => {
  return {
    type: MFA_VERIFICATION_FAILURE,
    errorMessage,
  };
};

const mfaRegistrationInitialized = (qrCodeData: string): Action => {
  return {
    type: MFA_REGISTRATION_INITIALIZED,
    qrCodeData,
  };
};

const mfaRegistrationSuccess = (): Action => {
  return {
    type: MFA_REGISTRATION_SUCCESS,
  };
};

const mfaRegistrationFailure = (errorMessage: string): Action => {
  return {
    type: MFA_REGISTRATION_FAILURE,
    errorMessage,
  };
};

const login = (
  authToken: string,
  rememberMe?: boolean,
  sessionId?: string,
  isMasqueradeSession?: boolean,
): Action => {
  return {
    type: LOGIN,
    authToken,
    rememberMe,
    sessionId,
    isMasqueradeSession,
  };
};

const loginFailed = (error: string): Action => {
  return {
    type: LOGIN_FAILED,
    error,
  };
};

const setLogout = (): Action => {
  return { type: LOGOUT };
};

// THUNKS
const getSessionIdFromToken = (authToken: string): string | undefined => {
  const tokenPieces = authToken.split('.');
  if (!tokenPieces || tokenPieces.length < 2) {
    return undefined;
  }
  const content = JSON.parse(window.atob(tokenPieces[1]));
  return content.sessionId;
};

export const authenticationHandlerFactory =
  (
    dispatch: ThunkDispatch<State, any, any>,
    rememberMe?: boolean,
    signupType?: SignupType,
    isMasqueradeSession?: boolean,
  ) =>
  (response: AxiosResponse) => {
    dispatch(requestSent(REQUEST_LOGIN));
    // Save initial token
    const { token } = response.data;
    const sessionId = getSessionIdFromToken(token);
    persistStateInLocalStorage({
      authentication: { authToken: token, sessionId, rememberMe },
    });

    return instance
      .get('/organization-user')
      .then((orgUserResponse) => {
        // Identify user for fullstory user statistics
        if (process.env.REACT_APP_ENV === 'prod') {
          const displayName = `${orgUserResponse.data.firstName} ${orgUserResponse.data.lastName}`;
          FullStoryAPI('identify', orgUserResponse.data.id, {
            displayName,
            email: orgUserResponse.data.email,
          });
        }
        const orgUser: OrganizationUser = orgUserResponse.data;
        dispatch(setOrganizationUser(orgUser));
        // Exclude teams of read-only orgs while looking for default team
        const filteredSortedTeams = [...orgUser.teamMemberships]
          .filter(
            (t) =>
              !orgUser.organizations.find((o) => o.id === t.team.organizationId)
                ?.hasReadOnlyAccess,
          )
          .sort((a, b) => {
            if (a.createdAt < b.createdAt) return 1;
            if (a.createdAt > b.createdAt) return -1;
            return 0;
          });

        // Rules for determining a user's default-selected team during login:
        // 0. Exclude all teams of Read-only Organizations and Organizations of account type "Free"
        // 1. Assign the the default team of the user's primary Org, if they are a member
        // 2. Assign the Team the user joined first
        // 3. Fall back to the Personal workspace, if the user is not a member of any other teams
        let defaultTeamId = orgUser.teamId;

        const primaryOrg = orgUser.organizations.find(
          (org: Organization) =>
            !org.suspended &&
            !org.hasReadOnlyAccess &&
            !(org.accountStatus === AccountType.Free) &&
            !(org.accountStatus === AccountType.FreeLegacy),
        );
        if (
          primaryOrg &&
          filteredSortedTeams.filter((t) => t.teamId === primaryOrg.defaultTeamId)
            .length > 0
        ) {
          defaultTeamId = primaryOrg.defaultTeamId;
        } else if (filteredSortedTeams.length > 0) {
          defaultTeamId = filteredSortedTeams[0].teamId;
        }

        dispatch(setTeamMode(defaultTeamId));
        if (signupType === SignupType.LINK) {
          Analytics.trackSignUp(orgUser, signupType);
        }
        dispatch(login(token, rememberMe, sessionId, isMasqueradeSession));
        dispatch(requestSuccess(REQUEST_LOGIN));
        Analytics.trackSignIn(orgUser);
        return true;
      })
      .catch((error) => {
        console.error(error);
        const errorMessage =
          "Oops! Seems like we've found an issue. Please email support@pluralpolicy.com and we will fix this error right away.";
        dispatch(loginFailed(errorMessage));
        Analytics.trackSignInFailed();
        return false;
      });
  };

export const resetLoginRequest = (): Thunk => {
  return (dispatch) => {
    dispatch(resetRequest(REQUEST_LOGIN));
  };
};

const getLoginError = (message: string): string => {
  if (message.includes('401')) {
    return 'Email and password combination is incorrect. Need help? Click the "Forgot Password?" link.';
  }
  if (message.includes('Network Error')) {
    return 'Oops! Seems like the network is temporarily down. Please try again later or email support@pluralpolicy.com';
  }
  return "Oops! Seems like we've found an issue. Please email support@pluralpolicy.com and we will fix this error right away.";
};

export const authenticate = (
  email: string,
  password: string,
  rememberMe?: boolean,
  signupType?: SignupType,
): Thunk => {
  return (dispatch) => {
    dispatch(requestSent());
    instance
      .post('/auth-token', {
        email,
        password,
        rememberMe,
      })
      .then(authenticationHandlerFactory(dispatch, rememberMe, signupType))
      .catch((error) => {
        console.error(error);
        const errorCode = error.response ? error.response.data.message : undefined;
        if (errorCode === 'needs-mfa-check') {
          return dispatch(loadMfaCheck());
        }
        if (errorCode === 'needs-mfa-registration') {
          return dispatch(loadMfaRegistrationRequired());
        }
        if (errorCode.includes('Account suspended')) {
          return dispatch(showSuspendedUserMessage());
        }
        Analytics.trackSignInFailed();
        return dispatch(loginFailed(getLoginError(error.message)));
      });
  };
};

export const authenticateForMasqueradedUser = (
  sessionId: string,
  signupType?: SignupType,
): Thunk => {
  const rememberMe = false;
  return (dispatch) => {
    dispatch(requestSent());
    instance
      .post('/auth-token/masquerade', { sessionId })
      .then(authenticationHandlerFactory(dispatch, rememberMe, signupType, true))
      .catch((error) => {
        console.error(error);
        Analytics.trackSignInFailed();
        return dispatch(loginFailed(getLoginError(error.message)));
      });
  };
};

export const authenticateForSso = (tempToken: string): Thunk => {
  return (dispatch) => {
    dispatch(requestSent());
    instance
      .post('/v2/auth/long-lived-token', { tempToken })
      .then(authenticationHandlerFactory(dispatch))
      .catch((error) => {
        console.error(error);
        Analytics.trackSignInFailed();
        return dispatch(loginFailed(getLoginError(error.message)));
      });
  };
};

export const resetPasswordRequest = (email: string): Thunk => {
  return (dispatch) => {
    dispatch(requestSent(REQUEST_PASSWORD_RESET));
    instance
      .post(`/forgot-password/request`, { email })
      .then(() => {
        dispatch(requestSuccess(REQUEST_PASSWORD_RESET));
      })
      .catch((error) => {
        const defaultErrorMsg = 'A system error occurred';
        const message = `${get(
          error,
          'response.data.message',
          defaultErrorMsg,
        )}. Please contact support@pluralpolicy.com`;
        dispatch(requestFailure(REQUEST_PASSWORD_RESET, { ...error, message }));
      });
  };
};

export const resetPassword = (authToken: string, password: string): Thunk => {
  return (dispatch) => {
    dispatch(requestSent(REQUEST_SET_NEW_PASSWORD));

    instance
      .post(
        '/forgot-password/reset',
        { password },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        },
      )
      .then((response) => {
        if (response.data.error) {
          dispatch(requestFailure(REQUEST_SET_NEW_PASSWORD, response.data.message));
        } else {
          dispatch(requestSuccess(REQUEST_SET_NEW_PASSWORD));
        }
      })
      .catch((error) => {
        dispatch(requestFailure(REQUEST_SET_NEW_PASSWORD, error));
      });
  };
};

export const resetPasswordRequestLoading = (): Thunk => {
  return (dispatch) => {
    dispatch(resetRequest(REQUEST_PASSWORD_RESET));
  };
};

export const logout = (): Thunk => {
  return async (dispatch, getState) => {
    await instance
      .post('/session/logout')
      .catch(() =>
        console.log('failed to clear session cookie, continuing with logout'),
      );
    const orgUser = getState().account.organizationUser;
    Analytics.trackSignOut(orgUser);
    dispatch(baseAPI.util.resetApiState());
    dispatch(setLogout());
    window.localStorage.clear();
    window.sessionStorage.clear();
  };
};

export const initializeMfaRegistration = (): Thunk => {
  return (dispatch) => {
    instance
      .post('/auth/mfa/initialize-registration', {})
      .then((response) => {
        dispatch(mfaRegistrationInitialized(response.data.qrCodeData));
      })
      .catch((error) => {
        const errorMessage = getMfaErrorMessage(error.response.data.message, true);
        dispatch(mfaRegistrationFailure(errorMessage));
      });
  };
};

export const confirmMfaRegistration = (token: string): Thunk => {
  return (dispatch) => {
    instance
      .post('/auth/mfa/confirm-registration', { token })
      .then((response) => {
        const authenticationHandler = authenticationHandlerFactory(dispatch, false);
        authenticationHandler(response)
          .then(() => dispatch(mfaRegistrationSuccess()))
          .catch(() => {
            const errorMessage = getMfaErrorMessage();
            dispatch(mfaRegistrationFailure(errorMessage));
          });
      })
      .catch((error) => {
        const errorMessage = getMfaErrorMessage(error.response.data.message, true);
        dispatch(mfaRegistrationFailure(errorMessage));
      });
  };
};

export const verifyMfaToken = (token: string, rememberMe: boolean): Thunk => {
  return (dispatch) => {
    instance
      .post('/auth/tokens/verify-mfa', { token, rememberMe })
      .then((response) => {
        const authenticationHandler = authenticationHandlerFactory(
          dispatch,
          rememberMe,
        );
        authenticationHandler(response)
          .then(() => dispatch(mfaVerificationSuccess()))
          .catch(() => {
            const errorMessage = getMfaErrorMessage();
            dispatch(mfaVerificationFailure(errorMessage));
          });
      })
      .catch((error) => {
        const errorMessage = getMfaErrorMessage(error.response.data.message);
        dispatch(mfaVerificationFailure(errorMessage));
      });
  };
};
