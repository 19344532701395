import { connect } from 'react-redux';
import MfaVerification from '../components/MfaVerification';
import { verifyMfaToken } from '../dux';

const mapStateToProps = (state) => {
  return {
    verificationSuccessful: state.authentication.mfaVerificationSuccessful,
    errorMessage: state.authentication.mfaVerificationErrorMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    verifyToken: (token, rememberMe) => {
      dispatch(verifyMfaToken(token, rememberMe));
    },
  };
};

const MfaVerificationContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MfaVerification);

export default MfaVerificationContainer;
