import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-jsonschema-form';
import { withRouter } from 'react-router-dom';

class MfaVerification extends React.Component {
  submitToken = (e) => {
    this.props.verifyToken(e.formData.token, this.props.location.state?.rememberMe);
  };

  render() {
    const { errorMessage } = this.props;

    const schema = {
      type: 'object',
      required: ['token'],
      properties: {
        token: {
          type: 'string',
        },
      },
    };

    const uiSchema = {
      token: {
        'ui:placeholder': 'Six-digit code',
      },
    };
    return (
      <div>
        <div>
          <h3 className="text-center mb-5">Verify multi-factor authentication token</h3>
          {errorMessage && (
            <p
              className="alert alert-warning flash-error"
              // nosemgrep: typescript.react.security.audit.react-dangerouslysetinnerhtml.react-dangerouslysetinnerhtml
              dangerouslySetInnerHTML={{ __html: errorMessage }}
            />
          )}
        </div>
        <div>
          <div className="mb-3" style={{ fontWeight: 'normal' }}>
            Enter the six digit code from your authenticator application.
          </div>
          <Form
            className="form login-form"
            showErrorList
            schema={schema}
            uiSchema={uiSchema}
            onSubmit={this.submitToken}
            onError={() => {
              console.log('errors');
            }}
          >
            <div className="mb-3 login-btn" style={{ marginTop: '-25px' }}>
              <button
                id="submit-mfa-verification"
                type="submit"
                className="btn btn-primary"
              >
                Submit
              </button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

MfaVerification.propTypes = {
  verifyToken: PropTypes.func,
  errorMessage: PropTypes.string,
  verificationSuccessful: PropTypes.bool,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(MfaVerification);
