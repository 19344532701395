import { Bill } from '@enview/interface/types/bills/Bill';
import { ReactElement, useState } from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import GateTooltip, { GateTooltipFeature } from '../Tooltips/TooltipGate';
import { PositionStatementPopover } from './PositionStatementPopover';
import CommentCheckIcon from './CommentCheckIcon';
import '../Popovers/Popover.scss';
import './BillPosition.scss';

type BillPositionButtonProps = {
  bill: Bill;
  isDisabled?: boolean;
  showPlaceholderText?: boolean;
};

const BillPositionButton: React.FC<BillPositionButtonProps> = ({
  bill,
  isDisabled,
  showPlaceholderText,
}) => {
  const [showPositionPopover, setShowPositionPopover] = useState(false);

  const targetElement: ReactElement = (
    <div
      aria-disabled={isDisabled}
      className={`d-flex bill-tag-button position-button-wrap ${
        isDisabled && 'disabled'
      }`}
      id={`position-button-${bill.id}`}
      style={{ marginRight: '12px' }}
      title="Add Position Statement"
    >
      <CommentCheckIcon color={'#919191'} />
      {showPlaceholderText && (
        <p className="placeholder-text">
          {t('bill.sidebar.positionStatement.positionButton')}
        </p>
      )}
    </div>
  );

  const togglePopover = () => {
    if (isDisabled) {
      return;
    }
    setShowPositionPopover(!showPositionPopover);
  };

  return (
    <>
      <PositionStatementPopover
        closePopup={() => setShowPositionPopover(false)}
        positions={['bottom', 'right']}
        show={showPositionPopover}
        style={{ minWidth: '500px' }}
        target={targetElement}
        toggleCallback={togglePopover}
      />
      {isDisabled && (
        <GateTooltip
          accountType="pro"
          anchorId={`position-button-${bill.id}`}
          customContent={
            <Trans
              components={{
                tagLink: <a href={t('urls.tagBills')} target="_blank" />,
                gate: <p className="tooltip-header" />,
              }}
              i18nKey="featureGating.tooltipText.tagButton"
              key="featureGating.tooltipText.tagButton"
            />
          }
          featureName={GateTooltipFeature.BillTagging}
          place="right"
        />
      )}
    </>
  );
};

export default BillPositionButton;
