import React from 'react';
import { t } from 'i18next';

const CommentCheckIcon: React.FC<React.SVGProps<any>> = ({
  color = '#F79728',
  ...props
}) => {
  return (
    <svg
      aria-labelledby={t('altText.positionButton')}
      fill={color}
      height="1rem"
      viewBox="0 0 24 24"
      width="1rem"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M20 2H4c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h3v3.767L13.277 18H20c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zm0 14h-7.277L9 18.233V16H4V4h16v12z" />
      <path d="m17.207 7.207-1.414-1.414L11 10.586 8.707 8.293 7.293 9.707 11 13.414z" />
    </svg>
  );
};

export default CommentCheckIcon;
