import { RichTextNode, TextNode, RichTextNodeType } from '../types/actions/UserAction';
import React, { ReactElement } from 'react';
import { Text, Node, BaseEditor, BaseRange } from 'slate';
import { ReactEditor } from 'slate-react';
import { HistoryEditor } from 'slate-history';
// eslint-disable-next-line import/no-extraneous-dependencies
import { jsx } from 'slate-hyperscript';

declare module 'slate' {
  interface CustomTypes {
    Editor: CustomEditor;
    Element: RichTextNode;
    Text: TextNode | EmptyText;
    Range: BaseRange & { [key: string]: unknown };
  }
}

export type CustomEditor = BaseEditor &
  ReactEditor &
  HistoryEditor & {
    nodeToDecorations?: Map<Element, Range[]>;
  };

export type EmptyText = {
  text: string;
};

/** Render a rich-text annotation node (from a Slate.js editor) into
 * a React component, meant for static display in an HTML context
 * (displaying a list of annoations on a bill, for instance).
 * This function is called recursively for every child node of the
 * root node passed as the sole parameter, forming a component tree from the
 * recursive structure of the `RichTextNode`
 */
export function serializeToReactElement(
  node: RichTextNode,
  userId?: number,
): ReactElement {
  return serializeInternal(node, userId)[0];
}

function serializeInternal(
  node: RichTextNode,
  userId?: number,
  counter = 0,
): [ReactElement, number] {
  let nodeCounter = counter + 1;
  if (Text.isText(node)) {
    if (node.bold) {
      return [<strong key={nodeCounter}>{node.text}</strong>, nodeCounter];
    }
    if (node.italic) {
      return [<em key={nodeCounter}>{node.text}</em>, nodeCounter];
    }
    if (node.underline) {
      return [<u key={nodeCounter}>{node.text}</u>, nodeCounter];
    }
    if (node.code) {
      return [<code key={nodeCounter}>{node.text}</code>, nodeCounter];
    }
    return [
      <React.Fragment key={nodeCounter}>{node.text}</React.Fragment>,
      nodeCounter,
    ];
  }

  const children = (
    <React.Fragment key={nodeCounter}>
      {node.children?.map((n: RichTextNode) => {
        const [result, count] = serializeInternal(n, userId, nodeCounter);
        nodeCounter += count;
        return result;
      })}
    </React.Fragment>
  );

  switch (node.type) {
    case RichTextNodeType.QUOTE:
      return [
        <blockquote key={nodeCounter}>
          <p>{children}</p>
        </blockquote>,
        nodeCounter,
      ];
    case RichTextNodeType.PARAGRAPH:
      return [<p key={nodeCounter}>{children}</p>, nodeCounter];
    case RichTextNodeType.LINK:
      return [
        <a key={nodeCounter} href={node.url}>
          {children}
        </a>,
        nodeCounter,
      ];
    case RichTextNodeType.NUMBERED_LIST:
      return [<ol key={nodeCounter}>{children}</ol>, nodeCounter];
    case RichTextNodeType.BULLETED_LIST:
      return [<ul key={nodeCounter}>{children}</ul>, nodeCounter];
    case RichTextNodeType.LIST_ITEM:
      return [<li key={nodeCounter}>{children}</li>, nodeCounter];
    case RichTextNodeType.MENTION:
      // determine if the current user matches the mentioned user
      const userIsMentioned = userId && userId === node.organizationUserId;
      const classUsed = userIsMentioned
        ? 'rich-text-mention highlight'
        : 'rich-text-mention';
      return [
        <span
          className={classUsed}
          key={nodeCounter}
          style={{
            padding: '3px',
            margin: '0 1px',
            borderRadius: '6px',
            backgroundColor: '#eee',
          }}
        >
          @{node.character}
        </span>,
        nodeCounter,
      ];
    default:
      return [children, nodeCounter];
  }
}

/** Render a rich-text annotation node (from a Slate.js editor) into
 * a plain text string that is appropriate for CSV export or other
 * non-HTML display option
 */
export function serializeToPlainText(node: RichTextNode): string {
  if (Text.isText(node)) return node.text;
  if (node && node.children)
    // Top-level children of the root node are assumed to be paragraphs and separated by
    // a newline character
    return node.children
      .map((n) => {
        let str = '';
        // Node.texts returns a generator that gets all leaf text nodes, which we need
        // to loop through
        for (const [t] of Node.texts(n)) {
          // Insert spaces between nodes that don't already have them, prevents
          // lists, etc. from looking like mashedtogetherwords in the output
          if (str.slice(-1) !== ' ' && t?.text.length > 0 && t?.text[0] !== ' ')
            str += ` ${t.text}`;
          else str += `${t.text}`;
        }
        return str;
      })
      .join('\n');
  return '';
}

// Render a plain text string to a rich-text annotation node (from a Slate.js editor) for bill summary
// TODO: eventually make this a deserialization to slate-json compatible nodes, which would have a more general purpose
export function deserializeToBillSummaryNodes(text: string): RichTextNode[] {
  const separateNodes = text.split('\n').filter((string) => string.length > 1);
  const node = [];
  for (const line of separateNodes) {
    node.push(jsx('element', { type: RichTextNodeType.PARAGRAPH }, [{ text: line }]));
  }
  if (node.length > 0) {
    node.push(
      jsx('element', { type: RichTextNodeType.PARAGRAPH }, [
        { bold: true, text: 'Generated by AI' },
      ]),
    );
  }
  return node;
}

export function lookforMentions(node: RichTextNode, mentions: number[] = []): number[] {
  // check for a ContainerNode
  if (
    'type' in node &&
    node.type === 'mention' &&
    node.organizationUserId &&
    !mentions.includes(node.organizationUserId)
  ) {
    mentions.push(node.organizationUserId);
  } else if ('type' in node && node.children) {
    for (const child of node.children) {
      mentions = lookforMentions(child, mentions);
    }
  }
  return mentions;
}
