import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Logo from './Logo';
import { g as abilityGlossary } from '../config/ability';
import NewTopNav from '../scenes/PrimaryView/NavRedesign/TopNav/NewTopNav';
import featureFlags from '../featureFlags';
import NewPrimarySidebar from '../scenes/PrimaryView/NavRedesign/NewPrimarySidebar';
import React, { Component } from 'react';
import OptionalLoginContainer from '../containers/OptionalLoginContainer';

class PublicLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      sidebarMinimized: true,
    };
  }

  toggleMenuOpen = () => {
    this.setState((prevState) => ({
      menuOpen: !prevState.menuOpen,
    }));
  };

  toggleSidebarMinimized = () => {
    this.setState((prevState) => ({
      sidebarMinimized: !prevState.sidebarMinimized,
    }));
  };

  render() {
    const { sidebarMinimized } = this.state;
    const layoutClasses = ['primary-layout'];

    const newNav = (
      <div className={layoutClasses.join(' ')}>
        <NewTopNav
          setSidebarMinimized={this.toggleSidebarMinimized}
          sidebarMinimized={sidebarMinimized}
        />
        <div
          className={`primary-layout-wrapper${
            sidebarMinimized ? ' sidebar-minimized' : ''
          } d-flex flex-row`}
        >
          <NewPrimarySidebar
            minimize={this.toggleSidebarMinimized}
            isMinimized={sidebarMinimized}
          />
          <div
            className={`page-layout__viewport primary-layout-content ${
              sidebarMinimized ? ' sidebar-minimized' : ''
            } d-flex flex-column`}
          >
            <div className="flex-grow-1">{this.props.children}</div>
            <footer className="text-center m-3">
              <small>© Plural</small>
            </footer>
          </div>
        </div>
        {this.props.openOptionalLoginModal && this.props.hasEmailReferrer && (
          <OptionalLoginContainer />
        )}
      </div>
    );

    return newNav;
  }
}

PublicLayout.propTypes = {
  children: PropTypes.any,
  hasEmailReferrer: PropTypes.bool,
  onCheckPermission: PropTypes.func.isRequired,
  organizationUser: PropTypes.object,
  openOptionalLoginModal: PropTypes.bool,
};

export default PublicLayout;
