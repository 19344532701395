import React from 'react';

export default () => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 504 448"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ verticalAlign: "-0.125em" }}
    >
        <path
            d="M24.0001 16C24.0001 24.8 31.2001 32 40.0001 32H56.0001V160H24.0001C15.2001 160 8.00007 167.2 8.00007 176C8.00007 184.8 15.2001 192 24.0001 192H120C128.8 192 136 184.8 136 176C136 167.2 128.8 160 120 160H88.0001V16C88.0001 7.2 80.8001 0 72.0001 0H40.0001C31.2001 0 24.0001 7.2 24.0001 16ZM184 64C184 72.8 191.2 80 200 80H488C496.8 80 504 72.8 504 64C504 55.2 496.8 48 488 48H200C191.2 48 184 55.2 184 64ZM184 224C184 232.8 191.2 240 200 240H488C496.8 240 504 232.8 504 224C504 215.2 496.8 208 488 208H200C191.2 208 184 215.2 184 224ZM184 384C184 392.8 191.2 400 200 400H488C496.8 400 504 392.8 504 384C504 375.2 496.8 368 488 368H200C191.2 368 184 375.2 184 384ZM50.7001 291.9C61.8001 280.8 79.9001 281.4 90.3001 293.2C99.5001 303.7 99.2001 319.4 89.7001 329.5L4.30007 421.1C6.67572e-05 425.8 -1.19993 432.6 1.30007 438.4C3.80007 444.2 9.60007 448 16.0001 448H120C128.8 448 136 440.8 136 432C136 423.2 128.8 416 120 416H52.8001L113.1 351.4C133.8 329.2 134.3 295 114.4 272.1C91.9001 246.4 52.3001 245 28.1001 269.2L12.7001 284.7C6.50007 290.9 6.50007 301.1 12.7001 307.3C18.9001 313.5 29.1001 313.5 35.3001 307.3L50.7001 291.9Z"
            fill="black"
        />
    </svg>
);
