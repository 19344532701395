import { connect } from 'react-redux';
import OptionalLoginModal from '../components/OptionalLoginModal';
import { closeOptionalLoginModal } from '../dux/ModalDux';

const mapStateToProps = (state) => {
  return {
    openOptionalLoginModal: state.modals.modalOpenOptionalLogin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => {
      dispatch(closeOptionalLoginModal());
    },
  };
};

const OptionalLoginContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OptionalLoginModal);

export default OptionalLoginContainer;
