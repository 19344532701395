import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { getSelectedTeamIds } from '../../../dux/TeamSelectorDux';

const ConfirmButtonLabel = {
  TRACK: 'TRACK',
  UPDATE: 'UPDATE',
  UNTRACK: 'UNTRACK',
};

type TrackConfirmButtonProps = {
  isBillTracked: boolean;
  updateBillTracking: (e: React.SyntheticEvent) => void;
  trackDisabled?: boolean;
};

const TrackConfirmButton = (props: TrackConfirmButtonProps): ReactElement => {
  const { isBillTracked, updateBillTracking, trackDisabled = false } = props;
  const selectedTeams = useSelector(getSelectedTeamIds);
  let confirmButtonLabel;
  if (!isBillTracked) {
    confirmButtonLabel = ConfirmButtonLabel.TRACK;
  } else if (selectedTeams.length > 0) {
    confirmButtonLabel = ConfirmButtonLabel.UPDATE;
  } else {
    confirmButtonLabel = ConfirmButtonLabel.UNTRACK;
  }

  return (
    <button
      className="btn btn-sm btn-block btn-secondary"
      disabled={!isBillTracked ? trackDisabled : false}
      id="update-bill-tracking-status-button"
      onClick={updateBillTracking}
      type="button"
    >
      {confirmButtonLabel}
    </button>
  );
};

export default TrackConfirmButton;
