/* eslint-disable @typescript-eslint/unbound-method */
import React, { ReactElement, useRef, useState } from 'react';
import ConfirmActionPopover, {
  PopoverConfirmProps,
} from '../Popovers/ConfirmActionPopover';
import CommentCheckIcon from './CommentCheckIcon';
import { useTranslation } from 'react-i18next';
import Can from '../../../components/Can';
import { g as abilityGlossary } from '../../../config/ability';
import RichTextEditor from '../ActivityFeed/CommentForm/RichTextEditor';
import { Descendant } from 'slate';
import './BillPosition.scss';
import { SubmitHandler, useForm } from 'react-hook-form';

interface PositionStatementPopoverProps extends Partial<PopoverConfirmProps> {
  target: ReactElement;
  show: boolean;
  toggleCallback: (evt: React.SyntheticEvent) => void;
  closePopup: () => void;
}

type PositionFormInput = { checkbox: string[] };

const PLACEHOLDER_TEXT = 'Add a position statement to this bill (optional)';

export const PositionStatementPopover: React.FC<PositionStatementPopoverProps> = ({
  target,
  show,
  toggleCallback,
  closePopup,
  ...props
}) => {
  const [editMode, setEditMode] = useState(false);
  const [comment, setComment] = useState(PLACEHOLDER_TEXT as any);
  const { t } = useTranslation();
  const node: any = useRef();

  const { register, handleSubmit, setValue, watch } = useForm<PositionFormInput>({
    defaultValues: {
      checkbox: [],
    },
  });

  const checkboxValue = watch('checkbox');

  const handleCheckboxChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;
    setValue('checkbox', checkboxValue.includes(value) ? [] : [value]);
  };

  const onSubmit: SubmitHandler<PositionFormInput> = (data) => {
    console.log(data);
    // TODO: Implement
    alert(`make post request with ${comment[0]} and support / oppose`);
    closePopup();
  };

  const onChangeToEditor = (event: Descendant[]) => {
    setComment(event);
  };

  return (
    <ConfirmActionPopover
      {...props}
      closePopup={closePopup}
      content={
        editMode ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <h4 className="popover-header">
              <CommentCheckIcon className="mr-2" color={'#212529'} />
              {t('bill.sidebar.positionStatement.modalheader')}
            </h4>
            <div className="form-check input-group">
              <input
                checked={checkboxValue.includes('support')}
                className="form-check-input"
                id="supportCheckbox"
                name="checkbox"
                onChange={handleCheckboxChange}
                ref={register}
                type="checkbox"
                value="support"
              />
              <label className="form-check-label" htmlFor="supportCheckbox">
                {t('bill.sidebar.positionStatement.support')}
              </label>
            </div>
            <div className="form-check input-group">
              <input
                checked={checkboxValue.includes('oppose')}
                className="form-check-input"
                id="opposeCheckbox"
                name="checkbox"
                onChange={handleCheckboxChange}
                ref={register}
                type="checkbox"
                value="oppose"
              />
              <label className="form-check-label" htmlFor="opposeCheckbox">
                {t('bill.sidebar.positionStatement.oppose')}
              </label>
            </div>
            <div className="rich-text-editor my-4" id="text-editor" ref={node}>
              <RichTextEditor
                onChangeToEditor={onChangeToEditor}
                placeholder={PLACEHOLDER_TEXT}
              />
            </div>
            <Can
              I={abilityGlossary.PUBLISH}
              a={abilityGlossary.BILL_POSITION}
              passThrough
            >
              {(can: boolean) => (
                <div className="form-check input-group">
                  <input
                    className="form-check-input"
                    disabled={!can}
                    id="publishCheckbox"
                    name="checkbox"
                    ref={register}
                    type="checkbox"
                    value="isPublic"
                  />
                  <label className="form-check-label" htmlFor="publishCheckbox">
                    {t('bill.sidebar.positionStatement.publish')}
                  </label>
                </div>
              )}
            </Can>
            <div className="d-flex justify-content-start mt-3">
              <button className="btn primary-btn" type="submit">
                Save
              </button>
              <button className="btn ml-3" onClick={() => closePopup()}>
                Cancel
              </button>
            </div>
          </form>
        ) : (
          <div>
            <h4 className="popover-header">
              <CommentCheckIcon className="mr-2" color={'#212529'} />
              {t('bill.sidebar.positionStatement.modalheader')}
            </h4>
            <div className="content-body my-3">
              <div
                className="position-button mb-2"
                style={{ backgroundColor: '#00cbb1' }}
              >
                Support
              </div>
              <div className="statement-box mt-4">
                {/* TODO: add statement */}
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur
                assumenda suscipit ad, omnis nostrum quam ipsa incidunt aut repellat
                ipsum. Sunt nulla quibusdam, tempore suscipit dicta fugit nobis commodi
                veritatis.
              </div>
            </div>
            <div className="footer d-flex">
              <button
                className="btn edit-btn ml-auto"
                onClick={() => setEditMode(true)}
              >
                Edit
              </button>
            </div>
          </div>
        )
      }
      show={show}
      target={target}
      toggleCallback={toggleCallback}
    />
  );
};
