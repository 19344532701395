import { connect } from 'react-redux';
import PublicLayout from '../components/PublicLayout';
import { checkPermission } from '../dux';
import { closeOptionalLoginModal } from '../dux/ModalDux';

const mapStateToProps = (state) => {
  return {
    openRestrictionModal: state.modals.modalOpenLoginRestriction,
    openOptionalLoginModal: state.modals.modalOpenOptionalLogin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCheckPermission: (permission) => {
      return dispatch(checkPermission(permission));
    },
    onCloseOptionalLoginModal: () => dispatch(closeOptionalLoginModal()),
  };
};

const PublicLayoutContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PublicLayout);

export default PublicLayoutContainer;
