import React, { CSSProperties } from 'react';

type PaperclipIconProps = {
    style?: CSSProperties;
}

export default (props: PaperclipIconProps) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 256 512"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={props.style}
    >
        <path
            d="M0 96C0 43 43 0 96 0C149 0 192 43 192 96V352C192 387.3 163.3 416 128 416C92.7 416 64 387.3 64 352V144C64 135.2 71.2 128 80 128C88.8 128 96 135.2 96 144V352C96 369.7 110.3 384 128 384C145.7 384 160 369.7 160 352V96C160 60.7 131.3 32 96 32C60.7 32 32 60.7 32 96V384C32 437 75 480 128 480C181 480 224 437 224 384V144C224 135.2 231.2 128 240 128C248.8 128 256 135.2 256 144V384C256 454.7 198.7 512 128 512C57.3 512 0 454.7 0 384V96Z"
            fill="black"
        />
    </svg>
);
