import { BillSearchCriteria, SavedSearch } from '@enview/interface/types/BillSearch';
import FeedbackData from '@enview/interface/types/actions/FeedbackData';
import { UserAction } from '@enview/interface/types/actions/UserAction';
import { Tag } from '@enview/interface/types/tags/Tag';
import assign from 'lodash-es/assign';
import ability, { g as abilityGlossary } from '../../config/ability';
import { BillGroup } from '../../models/BillGroup';
import { Action, Thunk } from '../@types';
import {
  MODAL_CLOSE_EDIT_SEARCH,
  MODAL_CLOSE_EXPORT_BILLS,
  MODAL_CLOSE_LOGIN_RESTRICTION,
  MODAL_CLOSE_OPTIONAL_LOGIN,
  MODAL_CLOSE_REPORT_PROBLEM,
  MODAL_CLOSE_SAVED_SEARCH,
  MODAL_CLOSE_SAVED_SEARCH_ALERT,
  MODAL_CLOSE_TAG,
  MODAL_CLOSE_USER_ACTION,
  MODAL_OPEN_EDIT_SEARCH,
  MODAL_OPEN_EXPORT_BILLS,
  MODAL_OPEN_LOGIN_RESTRICTION,
  MODAL_OPEN_OPTIONAL_LOGIN,
  MODAL_OPEN_REPORT_PROBLEM,
  MODAL_OPEN_SAVED_SEARCH,
  MODAL_OPEN_SAVED_SEARCH_ALERT,
  MODAL_OPEN_TAG,
  MODAL_OPEN_USER_ACTION,
  ModalState,
  SavedSearchEditAction,
} from './types';

// REDUCER
export default function reducer(state: ModalState, action: Action): ModalState {
  switch (action.type) {
    case MODAL_OPEN_USER_ACTION:
      return assign({}, state, {
        modalOpenUserAction: true,
        modalUserAction: action.userAction,
      });
    case MODAL_CLOSE_USER_ACTION:
      return assign({}, state, {
        modalOpenUserAction: false,
        modalUserAction: undefined,
      });
    case MODAL_OPEN_EXPORT_BILLS:
      return assign({}, state, {
        modalOpenExportBills: true,
        modalPreselectedBillGroups: action.preselectedBillGroups,
      });
    case MODAL_CLOSE_EXPORT_BILLS:
      return assign({}, state, {
        modalOpenExportBills: false,
        modalPreselectedBillGroups: undefined,
      });
    case MODAL_OPEN_TAG:
      return assign({}, state, {
        modalOpenTag: true,
        modalTag: action.tag,
        modalIsPublishTag: action.isPublish,
      });
    case MODAL_CLOSE_TAG:
      return assign({}, state, {
        modalOpenTag: false,
        modalTag: undefined,
        modalIsPublishTag: false,
      });
    case MODAL_OPEN_LOGIN_RESTRICTION:
      return assign({}, state, {
        modalOpenLoginRestriction: true,
      });
    case MODAL_CLOSE_LOGIN_RESTRICTION:
      return assign({}, state, {
        modalOpenLoginRestriction: false,
      });
    case MODAL_OPEN_OPTIONAL_LOGIN:
      return assign({}, state, {
        modalOpenOptionalLogin: true,
      });
    case MODAL_CLOSE_OPTIONAL_LOGIN:
      return assign({}, state, {
        modalOpenOptionalLogin: false,
      });
    case MODAL_OPEN_SAVED_SEARCH:
      return assign({}, state, {
        modalOpenSavedSearch: true,
        modalSavedSearchCriteria: {
          useDefaultSession:
            !action.criteria.sessionNames || action.criteria.sessionNames.length === 0,
          ...action.criteria,
        },
      });
    case MODAL_CLOSE_SAVED_SEARCH:
      return assign({}, state, {
        modalOpenSavedSearch: false,
        modalSavedSearchCriteria: {},
      });
    case MODAL_OPEN_EDIT_SEARCH:
      return assign({}, state, {
        modalOpenEditSearch: true,
        modalSavedSearch: action.search,
        modalEditSearchAction: action.editAction,
      });
    case MODAL_CLOSE_EDIT_SEARCH:
      return assign({}, state, {
        modalOpenEditSearch: false,
        modalSavedSearch: {},
        modalIsArchivingSearch: false,
      });
    case MODAL_OPEN_SAVED_SEARCH_ALERT:
      return assign({}, state, {
        modalOpenSavedSearchAlert: true,
        modalOpenSavedSearchAlertSubscribed: action.isSubscribed,
      });
    case MODAL_CLOSE_SAVED_SEARCH_ALERT:
      return assign({}, state, {
        modalOpenSavedSearchAlert: false,
      });
    case MODAL_OPEN_REPORT_PROBLEM:
      return assign({}, state, {
        modalReportProblemData: action.data,
        modalOpenReportProblem: true,
      });
    case MODAL_CLOSE_REPORT_PROBLEM:
      return assign({}, state, {
        modalOpenReportProblem: false,
      });
    default:
      return state || {};
  }
}

// ACTION CREATORS
const openUserAction = (userAction?: Partial<UserAction>): Action => {
  return {
    type: MODAL_OPEN_USER_ACTION,
    userAction,
  };
};

export const closeUserActionModal = (): Action => {
  return {
    type: MODAL_CLOSE_USER_ACTION,
  };
};

const openExportModal = (preselectedBillGroups?: BillGroup[]): Action => {
  return {
    type: MODAL_OPEN_EXPORT_BILLS,
    preselectedBillGroups,
  };
};

export const closeExportBillsModal = (): Action => {
  return {
    type: MODAL_CLOSE_EXPORT_BILLS,
  };
};

export const openSavedSearchModal = (criteria: BillSearchCriteria): Action => {
  return {
    type: MODAL_OPEN_SAVED_SEARCH,
    criteria,
  };
};

export const closeSavedSearchModal = (): Action => {
  return {
    type: MODAL_CLOSE_SAVED_SEARCH,
  };
};

export const openEditSearchModal = (
  search: SavedSearch,
  editAction: SavedSearchEditAction,
): Action => {
  return {
    type: MODAL_OPEN_EDIT_SEARCH,
    search,
    editAction,
  };
};

export const closeEditSearchModal = (): Action => {
  return {
    type: MODAL_CLOSE_EDIT_SEARCH,
  };
};

export const openSavedSearchAlertModal = (
  isSubscribed: boolean,
  isError: boolean,
): Action => {
  return {
    type: MODAL_OPEN_SAVED_SEARCH_ALERT,
    isError,
    isSubscribed,
  };
};

export const closeSavedSearchAlertModal = (): Action => {
  return {
    type: MODAL_CLOSE_SAVED_SEARCH_ALERT,
  };
};

export const openTagModal = (tag?: Tag): Action => {
  return {
    type: MODAL_OPEN_TAG,
    tag,
    isPublish: false,
  };
};

export const openTagPublishModal = (tag: Tag): Action => {
  return {
    type: MODAL_OPEN_TAG,
    tag,
    isPublish: true,
  };
};

export const closeTagModal = (): Action => {
  return {
    type: MODAL_CLOSE_TAG,
  };
};

// TODO: Remove all references to restriction modal
// const openLoginRestrictionModal = (): Action => {
//   return {
//     type: MODAL_OPEN_LOGIN_RESTRICTION,
//   };
// };

export const openOptionalLoginModal = (): Action => {
  return {
    type: MODAL_OPEN_OPTIONAL_LOGIN,
  };
};

export const closeOptionalLoginModal = (): Action => {
  return {
    type: MODAL_CLOSE_OPTIONAL_LOGIN,
  };
};

export const closeLoginRestrictionModal = (): Action => {
  return {
    type: MODAL_CLOSE_LOGIN_RESTRICTION,
  };
};

export const openReportProblemModal = (data: FeedbackData): Action => {
  return {
    type: MODAL_OPEN_REPORT_PROBLEM,
    data,
  };
};

export const closeReportProblemModal = (): Action => {
  return {
    type: MODAL_CLOSE_REPORT_PROBLEM,
  };
};

// TODO: Remove all references to restriction modal and new gate
export const checkPermission = (
  action: string,
  subject: string,
  _newGate?: boolean,
): Thunk<boolean> => {
  return () => {
    return ability.can(action, subject);
  };
};

// THUNKS
export const openNewUserActionModal = (): Thunk => {
  return (dispatch) => {
    if (
      dispatch(checkPermission(abilityGlossary.CREATE, abilityGlossary.BILL_ANNOTATION))
    ) {
      dispatch(openUserAction());
    }
  };
};

export const openEditUserActionModal = (userAction: UserAction): Thunk => {
  return (dispatch) => {
    if (
      dispatch(checkPermission(abilityGlossary.EDIT, abilityGlossary.BILL_ANNOTATION))
    ) {
      dispatch(openUserAction(userAction));
    }
  };
};

export const openReplyUserActionModal = (userAction: UserAction): Thunk => {
  return (dispatch) => {
    if (
      dispatch(checkPermission(abilityGlossary.CREATE, abilityGlossary.BILL_ANNOTATION))
    ) {
      const newUserAction = { replyTo: userAction };
      dispatch(openUserAction(newUserAction));
    }
  };
};

export const openExportBillsModal = (selectedOptions?: BillGroup[]): Thunk => {
  return (dispatch) => {
    if (dispatch(checkPermission(abilityGlossary.EXPORT, abilityGlossary.BILL))) {
      dispatch(openExportModal(selectedOptions));
    }
  };
};
