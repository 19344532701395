import { BillAlias, BillSummary } from '../bills/BillSummary';
import { BillAnnotation } from '../bills/BillAnnotation';
import { LegislativeSessionWithState } from '../LegislativeSession';
import { Organization } from '../Organization';
import { OrganizationUser } from '../OrganizationUser';
import { Person } from '../Person';

export type UserAction = {
  id: number;
  billId: string;
  createdAt: string;
  updatedAt: string;
  organizationUser: OrganizationUser;
  organizationId: number;
  organizationName: string;
  organization: Organization;
  sharedToTeamDiscussions: []; // TODO
  teams: number[];
  replyTo?: UserAction;
  isStarred: boolean;
  isArchived: boolean;
  sourceFeature?: string;
  comment?: string;
  commentJson?: RichTextNode;
  annotation?: BillAnnotation;
  selectedText?: string;
  userFileIds: number[];
  userFiles: any[];
  billData?: {
    billId: string;
    billVersionId?: number;
    bill?: AnnotationBillDetails;
  };
  person?: Person;
  mentionUserIds: number[];
};

/** A list of the different supported types of rich-text nodes available in our Slate.js
 * editor. Each of these types may be associated with different rendering behavior
 * in editable containers, static HTML, or plain-text export contexts
 */
export enum RichTextNodeType {
  PARAGRAPH = 'paragraph',
  QUOTE = 'quote',
  LINK = 'link',
  BULLETED_LIST = 'bulleted-list',
  NUMBERED_LIST = 'numbered-list',
  LIST_ITEM = 'list-item',
  MENTION = 'mention',
  SUMMARY = 'summary',
}

export type RichTextNode = TextNode | ContainerNode;

export type TextNode = {
  align?: string;
  /** Text content, if any, of the node. Generally, nodes will either have a defined `text`
   * OR a non-zero list of `children`, but not both. Nodes with non-empty text are considered
   * "text nodes" for the purposes of serialization/deserialization
   */
  text: string;
  /** If true, this node represents a contiguous range of text meant to be rendered in bold-face type
   */
  bold?: boolean;
  /** If true, this node repsensts a contiguous range of text meant to be rendered in a monospace
   * font and a different color than the surrounding text
   */
  code?: boolean;
  /** If true, this node repsensts a contiguous range of text meant to be rendered in italic type
   */
  italic?: boolean;
  /** If true, this node repsensts a contiguous range of text meant to be rendered with an underline
   */
  underline?: boolean;
};

/** An object representation of a user-created rich text annotation or note.
 * The data model here matches the output of Slate.js with our custom
 * formatting options such as underlining and bulleted lists
 */
export type ContainerNode = {
  type?: RichTextNodeType;
  character?: string;
  organizationUserId?: number;
  /** URL that a link should point to. Used for nodes that are of `link` type
   */
  url?: string;
  /** Nodes that are contained within this node. Rich-text annotations are represented as a tree structure
   * similar to the DOM, where any node has a single parent but can have many children
   */
  children: Array<RichTextNode | TextNode>;
};

export type Attributes = {
  'data-slate-leaf': boolean;
};

export type AnnotationBillDetails = {
  name: string;
  number: string;
  userAliases: BillAlias[];
  session: LegislativeSessionWithState;
};

export type LegacyAnnotation = {
  id: number;
  createdAt: string;
  updatedAt: string;
  organizationUser: OrganizationUser;
  organizationId: number;
  organizationName: string;
  organization: Organization;
  sharedToTeamDiscussions: []; // TODO
  teams: number[];
  replyToId: number;
  isStarred: boolean;
  isArchived: boolean;
  sourceFeature?: string;
  comment?: string;
  commentJson?: RichTextNode;
  userFileIds: number[];
  userFiles: any[];
  bill?: BillSummary;
  billId?: string;
  billVersionId?: number;
  fromLine?: string;
  fromCharacter?: number;
  toLine?: string;
  toCharacter?: number;
  selectedText?: string;
  personId?: number;
  mentionUserIds: number[];
};
