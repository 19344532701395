import React from 'react';

export default () => (
  <svg
    width="0.875em"
    height="1em"
    viewBox="0 0 448 448"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ verticalAlign: "-0.125em" }}
  >
    <path
      d="M0 16C0 7.2 7.2 0 16 0H144C152.8 0 160 7.2 160 16C160 24.8 152.8 32 144 32H96V192C96 262.7 153.3 320 224 320C294.7 320 352 262.7 352 192V32H304C295.2 32 288 24.8 288 16C288 7.2 295.2 0 304 0H432C440.8 0 448 7.2 448 16C448 24.8 440.8 32 432 32H384V192C384 280.4 312.4 352 224 352C135.6 352 64 280.4 64 192V32H16C7.2 32 0 24.8 0 16ZM0 432C0 423.2 7.2 416 16 416H432C440.8 416 448 423.2 448 432C448 440.8 440.8 448 432 448H16C7.2 448 0 440.8 0 432Z"
      fill="black"
    />
  </svg>
);
