import { g } from '../ability';

export default function defineFreeLegacyUserAbilities(can) {
  can([g.VIEW], g.BILL_ANNOTATION_TOOLTIP);
  can([g.VIEW], g.TRACK_BILL_CONFIRM);
  can([g.VIEW], g.BILL_SEARCH);
  can([g.TRACK, g.VIEW], g.BILL);
  can([g.VIEW], g.SIDEBAR_SEARCH);
  can([g.VIEW], g.SIDEBAR_WORKSPACE);
  can([g.VIEW], g.SIDEBAR_BILLS);
  can([g.VIEW], g.SIDEBAR_SEARCHES);
  can([g.VIEW], g.SIDEBAR_SEARCH_NAV_GROUP);
  can([g.CREATE, g.EDIT, g.VIEW], g.SAVED_SEARCHES);
  can([g.VIEW], g.FREE_SAVED_SEARCH_NOTIF_SETTINGS);
  can([g.SEND], g.BILL);
  can([g.VIEW], g.BILL_SUMMARY);
}
